// Messages
//
// Show alert messages to users. You may add it to single elements like a `<p>`,
// or to a parent if there are multiple elements to show.

.message {
  padding: 30px;
  margin-bottom: 30px;
  color: $white;
  background-color: $gray;
  border-radius: 3px;
}
