.map {
    position: relative;
    padding-bottom: 60%;
    margin-top: 30px;
    height: 0;
    overflow: hidden;
    max-width: 100%;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
