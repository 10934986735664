.search-bar {
  display: block;
  width: 100%;
  height: 45px;
  padding: 08px 12px;
  font-size: 18px;
  line-height: 1.66666666667;
  border: 1px solid #dddddd;
  border-radius: 03px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;

  &:focus,
  &:hover {
    border-color: $blue;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px $blue;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px $blue;
    filter: opacity(0.6);
  }

  &::-moz-placeholder {
    color: $gray;
    opacity: 0.7;
  }

  &:-ms-input-placeholder,
  &::-webkit-input-placeholder {
    color: $gray;
    opacity: 0.7;
  }

  input {
    &[type=text] {
      height: 45px;
      padding: 10px 15px;
      font-size: 18px;
      line-height: 1.66666666667;
      border-radius: 06px;
    }
  }
}

.search-title a {
  h3 {
    margin: 0;
    color: $blue;
  }

  &:hover {
    text-decoration: none;
  }
}
